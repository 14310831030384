// * Base Url API * //
const BASE_URL = "https://admin.wasil-iq.com/api/v10";
// const BASE_URL = "https://wasil_fix.test/api/v10";
// * Src Image API * //
export const SRC_IMG_API = "https://admin.wasil-iq.com/public";
// * Endpoints API * //
export const GENERAL_SETTINGS = `${BASE_URL}/info`;
export const CITIES_API = `${BASE_URL}/get_cities`;
export const REGISTER_API = `${BASE_URL}/register`;
export const LOGIN_API = `${BASE_URL}/signin`;
export const SEND_OTP_API = `${BASE_URL}/send-otp`;
export const CHECK_OTP_API = `${BASE_URL}/otp-verification-email`;
export const REST_PASSWORD_API = `${BASE_URL}/change-otp-password`;
export const LOGOUT_API = `${BASE_URL}/sign-out`;
export const CALCULATOR_API = `${BASE_URL}/calculator`;
export const CALCULATOR_POST_API = `${BASE_URL}/calculator/new`;
export const MEMBERSHIPS_API = `${BASE_URL}/landing_index`;
export const USER_DASHBOARD_API = `${BASE_URL}/user_dashboard`;
export const UPDATE_CURRENT_CODE_API = `${BASE_URL}/update_current_code`;
export const INBOX_API = `${BASE_URL}/get_on_box_packs`;
export const GET_PACKAGE_API = `${BASE_URL}/get_package`;
export const SEARCH_PACKAGES_API = `${BASE_URL}/search_my_packages`;
export const EDIT_PRICE_API = `${BASE_URL}/send_edit_price_request`;
export const SAVE_DV_API = `${BASE_URL}/save_dv_value`;
export const UPLOAD_MERCHANT_INVOICE_API = `${BASE_URL}/upload_merchant_invoice`;
export const RETURN_PACKAGE_API = `${BASE_URL}/return_package`;
export const GET_RETURN_PRICE_API = `${BASE_URL}/getReturnPrice`;
export const MY_MEMBERSHIPS_API = `${BASE_URL}/user/get-my-memberships`;
export const GET_PLANS_API = `${BASE_URL}/plans/byHubId`;
export const SUBSCRIBE_API = `${BASE_URL}/wasil-subscribe`;
export const PROMO_CODE_API = `${BASE_URL}/promocode/save`;
export const REMOVE_PROMO_CODE_API = `${BASE_URL}/promocode/remove`;
export const OUTBOX_API = `${BASE_URL}/get_out_box_packs`;
export const GET_USER_INFO = `${BASE_URL}/user/get-my-info`;
export const CHANGE_UER_ADDRESS = `${BASE_URL}/user/edit_address`;
export const CHANGE_UER_PASSWORD = `${BASE_URL}/password/change`;
export const GET_ARCHIVE_PACKS = `${BASE_URL}/archive`;
export const GET_USER_ADDRESS = `${BASE_URL}/delivery-address/getMyAddresses`;
export const CREATE_USER_ADDRESS = `${BASE_URL}/delivery-address/create`;
export const UPDATE_USER_ADDRESS = `${BASE_URL}/delivery-address/update`;
export const DELETE_USER_ADDRESS = `${BASE_URL}/delivery-address/delete/`;
export const GET_READY_SHIPMENTS = `${BASE_URL}/get_all_ready_shipments`;
export const CREATE_DELIVERY_REQUEST = `${BASE_URL}/delivery-requests/create`;
export const GET_USER_DELIVERY_REQUESTS = `${BASE_URL}/delivery-requests/getMyrequests`;
export const CANCEL_DELIVERY_REQUESTS = `${BASE_URL}/delivery-requests/cancel`;
export const GET_USER_WALLET = `${BASE_URL}/wallet/getMyWallet`;
export const GET_USER_INSURANCE = `${BASE_URL}/insurance/get`;
export const Activate_INSURANCE = `${BASE_URL}/insurance/active`;
export const DEActivate_INSURANCE = `${BASE_URL}/insurance/deactive`;